import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SupabaseApi from '../apis/SupabaseApi.js';
import * as CustomCode from '../custom-files/CustomCode';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  AccordionGroup,
  Icon,
  IconButton,
  Link,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  Table,
  TableCell,
  TableRow,
  VStack,
  WebView,
  YoutubePlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const SwiftHistoryResourcesScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const [caringText, setCaringText] = React.useState(true);
  const [contactUs, setContactUs] = React.useState([]);
  const [databaseArchives, setDatabaseArchives] = React.useState('');
  const [databaseLibrary, setDatabaseLibrary] = React.useState('');
  const [databaseMuseum, setDatabaseMuseum] = React.useState('');
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalYTVisible, setModalYTVisible] = React.useState(false);
  const [webReprorequest, setWebReprorequest] = React.useState('');
  const [webResearchrequest, setWebResearchrequest] = React.useState('');
  const [webView, setWebView] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          alignSelf: 'auto',
          backgroundColor: theme.colors['White'],
          justifyContent: 'space-between',
        },
        dimensions.width
      )}
    >
      <View>
        {/* Resource Text */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          accessibilityRole={'text'}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              fontSize: [
                { minWidth: Breakpoints.Laptop, value: 18 },
                { minWidth: Breakpoints.Mobile, value: 16 },
                { minWidth: Breakpoints.Desktop, value: 22 },
              ],
              margin: [
                { minWidth: Breakpoints.Mobile, value: 5 },
                { minWidth: Breakpoints.Laptop, value: 10 },
              ],
            }),
            dimensions.width
          )}
        >
          {
            'Below are resources that the Museum has created or selected for the convenience of users. Some may open in external windows or programs.'
          }
        </Text>
        {/* Disclaimer Text */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          accessibilityRole={'text'}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              fontFamily: 'System',
              fontSize: [
                { minWidth: Breakpoints.Mobile, value: 16 },
                { minWidth: Breakpoints.Laptop, value: 18 },
                { minWidth: Breakpoints.Desktop, value: 22 },
              ],
              fontStyle: 'italic',
              fontWeight: '400',
              margin: [
                { minWidth: Breakpoints.Mobile, value: 5 },
                { minWidth: Breakpoints.Laptop, value: 10 },
              ],
              textAlign: 'right',
            }),
            dimensions.width
          )}
        >
          {
            'The Museum is not responsible for the accuracy, currency, or reliability of third-party content.'
          }
        </Text>
        {/* Databases Accordion */}
        <AccordionGroup
          expanded={false}
          {...GlobalStyles.AccordionGroupStyles(theme)['Accordion'].props}
          caretSize={48}
          closedColor={theme.colors['Medium']}
          icon={'FontAwesome/database'}
          iconSize={60}
          label={'Databases'}
          openColor={theme.colors['Black']}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
              { fontSize: { minWidth: Breakpoints.Desktop, value: 22 } }
            ),
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            accessibilityRole={'text'}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                fontSize: [
                  { minWidth: Breakpoints.Desktop, value: 22 },
                  { minWidth: Breakpoints.Mobile, value: 16 },
                ],
                marginBottom: 10,
                marginLeft: 50,
                marginRight: 10,
              }),
              dimensions.width
            )}
          >
            {'Click below to go to our databases.'}
          </Text>

          <SupabaseApi.FetchDatabasesGET>
            {({ loading, error, data, refetchDatabases }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <SimpleStyleFlatList
                  data={fetchData}
                  horizontal={false}
                  inverted={false}
                  keyExtractor={(listData, index) => listData?.id}
                  keyboardShouldPersistTaps={'never'}
                  listKey={'psOR2OYW'}
                  nestedScrollEnabled={false}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <Pressable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              /* hidden 'Set Variable' action */
                              /* hidden 'Set Variable' action */
                              /* hidden 'Navigate' action */
                              await WebBrowser.openBrowserAsync(
                                `${listData?.url}`
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            borderRadius: 15,
                            borderWidth: 1,
                            marginBottom: 5,
                            marginRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 5,
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              padding: 10,
                            },
                            dimensions.width
                          )}
                        >
                          <>
                            {!listData?.image ? null : (
                              <Image
                                resizeMode={'cover'}
                                {...GlobalStyles.ImageStyles(theme)['Image']
                                  .props}
                                source={{ uri: `${listData?.image}` }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image']
                                      .style,
                                    {
                                      borderRadius: 5,
                                      marginRight: 10,
                                      minHeight: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 50,
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 60,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 60,
                                        },
                                      ],
                                      minWidth: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 50,
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 60,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 60,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            accessibilityRole={'button'}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'System',
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'System',
                                    },
                                  ],
                                  fontSize: [
                                    { minWidth: Breakpoints.Tablet, value: 18 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 22,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 24,
                                    },
                                  ],
                                  fontWeight: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: '400',
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: '400',
                                    },
                                  ],
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {listData?.name}
                          </Text>
                        </View>
                      </Pressable>
                    );
                  }}
                  showsVerticalScrollIndicator={true}
                  showsHorizontalScrollIndicator={false}
                  style={StyleSheet.applyWidth(
                    {
                      marginLeft: 50,
                      marginRight: 10,
                      width: { minWidth: Breakpoints.Laptop, value: '50%' },
                    },
                    dimensions.width
                  )}
                />
              );
            }}
          </SupabaseApi.FetchDatabasesGET>
        </AccordionGroup>
        {/* Research Accordian */}
        <AccordionGroup
          expanded={false}
          {...GlobalStyles.AccordionGroupStyles(theme)['Accordion'].props}
          caretSize={48}
          closedColor={theme.colors['Medium']}
          icon={'Feather/search'}
          iconSize={60}
          label={'Research'}
          openColor={theme.colors['Black']}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
              { fontSize: { minWidth: Breakpoints.Desktop, value: 22 } }
            ),
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            accessibilityRole={'text'}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                fontSize: [
                  { minWidth: Breakpoints.Desktop, value: 22 },
                  { minWidth: Breakpoints.Mobile, value: 16 },
                ],
                marginBottom: 10,
                marginLeft: 50,
                marginRight: 10,
              }),
              dimensions.width
            )}
          >
            {'Click below to go to the various resources.'}
          </Text>

          <SupabaseApi.FetchResearchGET>
            {({ loading, error, data, refetchResearch }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <SimpleStyleFlatList
                  data={fetchData}
                  horizontal={false}
                  inverted={false}
                  keyExtractor={(listData, index) => listData?.id}
                  keyboardShouldPersistTaps={'never'}
                  listKey={'iFpegZqp'}
                  nestedScrollEnabled={false}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <Pressable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              /* hidden 'Set Variable' action */
                              /* hidden 'Set Variable' action */
                              /* hidden 'Navigate' action */
                              await WebBrowser.openBrowserAsync(
                                `${listData?.url}`
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            borderRadius: 15,
                            borderWidth: 1,
                            marginBottom: 5,
                            marginRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 5,
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              padding: 10,
                            },
                            dimensions.width
                          )}
                        >
                          <>
                            {!listData?.image ? null : (
                              <Image
                                resizeMode={'cover'}
                                {...GlobalStyles.ImageStyles(theme)['Image']
                                  .props}
                                source={{ uri: `${listData?.image}` }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image']
                                      .style,
                                    {
                                      borderRadius: 5,
                                      marginRight: 10,
                                      minHeight: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 50,
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 60,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 60,
                                        },
                                      ],
                                      minWidth: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 50,
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 60,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 60,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            accessibilityRole={'button'}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'System',
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'System',
                                    },
                                  ],
                                  fontSize: [
                                    { minWidth: Breakpoints.Tablet, value: 18 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 22,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 24,
                                    },
                                  ],
                                  fontWeight: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: '400',
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: '400',
                                    },
                                  ],
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {listData?.name}
                          </Text>
                        </View>
                      </Pressable>
                    );
                  }}
                  showsVerticalScrollIndicator={true}
                  showsHorizontalScrollIndicator={false}
                  style={StyleSheet.applyWidth(
                    {
                      marginLeft: 50,
                      marginRight: 10,
                      width: { minWidth: Breakpoints.Laptop, value: '50%' },
                    },
                    dimensions.width
                  )}
                />
              );
            }}
          </SupabaseApi.FetchResearchGET>
        </AccordionGroup>
        {/* Requests Accordian */}
        <AccordionGroup
          expanded={false}
          {...GlobalStyles.AccordionGroupStyles(theme)['Accordion'].props}
          caretSize={48}
          closedColor={theme.colors['Medium']}
          icon={'Feather/edit'}
          iconSize={60}
          label={'Requests'}
          openColor={theme.colors['Black']}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
              { fontSize: { minWidth: Breakpoints.Desktop, value: 22 } }
            ),
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            accessibilityRole={'text'}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                fontSize: [
                  { minWidth: Breakpoints.Desktop, value: 22 },
                  { minWidth: Breakpoints.Mobile, value: 16 },
                ],
                marginBottom: 10,
                marginLeft: 50,
                marginRight: 10,
              }),
              dimensions.width
            )}
          >
            {'Click below to go to our request forms.'}
          </Text>

          <SupabaseApi.FetchRequestsGET>
            {({ loading, error, data, refetchRequests }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <SimpleStyleFlatList
                  data={fetchData}
                  horizontal={false}
                  inverted={false}
                  keyExtractor={(listData, index) => listData?.id}
                  keyboardShouldPersistTaps={'never'}
                  listKey={'MthNGW7a'}
                  nestedScrollEnabled={false}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <Pressable
                        onPress={() => {
                          try {
                            /* hidden 'Set Variable' action */
                            /* hidden 'Set Variable' action */
                            navigation.navigate('StackNavigator', {
                              screen: 'SwiftHistoryBrowserScreen',
                              params: { url: listData?.url },
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            borderRadius: 15,
                            borderWidth: 1,
                            marginBottom: 5,
                            marginRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 5,
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              padding: 10,
                            },
                            dimensions.width
                          )}
                        >
                          <>
                            {!listData?.image ? null : (
                              <Image
                                resizeMode={'cover'}
                                {...GlobalStyles.ImageStyles(theme)['Image']
                                  .props}
                                source={{ uri: `${listData?.image}` }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image']
                                      .style,
                                    {
                                      borderRadius: 5,
                                      marginRight: 10,
                                      minHeight: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 50,
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 60,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 60,
                                        },
                                      ],
                                      minWidth: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 50,
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 60,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 60,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            accessibilityRole={'button'}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  fontFamily: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 'System',
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'System',
                                    },
                                  ],
                                  fontSize: [
                                    { minWidth: Breakpoints.Tablet, value: 18 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 22,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 24,
                                    },
                                  ],
                                  fontWeight: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: '400',
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: '400',
                                    },
                                  ],
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {listData?.name}
                          </Text>
                        </View>
                      </Pressable>
                    );
                  }}
                  showsVerticalScrollIndicator={true}
                  showsHorizontalScrollIndicator={false}
                  style={StyleSheet.applyWidth(
                    {
                      marginLeft: 50,
                      marginRight: 10,
                      width: { minWidth: Breakpoints.Laptop, value: '50%' },
                    },
                    dimensions.width
                  )}
                />
              );
            }}
          </SupabaseApi.FetchRequestsGET>
        </AccordionGroup>
        {/* Caring Accordian */}
        <AccordionGroup
          expanded={false}
          {...GlobalStyles.AccordionGroupStyles(theme)['Accordion'].props}
          caretSize={48}
          closedColor={theme.colors['Medium']}
          icon={'MaterialCommunityIcons/treasure-chest'}
          iconSize={60}
          label={'Caring for Items'}
          openColor={theme.colors['Black']}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
              { fontSize: { minWidth: Breakpoints.Desktop, value: 22 } }
            ),
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            accessibilityRole={'text'}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                fontSize: [
                  { minWidth: Breakpoints.Desktop, value: 22 },
                  { minWidth: Breakpoints.Mobile, value: 16 },
                ],
                marginBottom: 10,
                marginLeft: 50,
                marginRight: 10,
              }),
              dimensions.width
            )}
          >
            {
              'Click below to open the resources that can help you care for your family treasures.'
            }
          </Text>
          {/* CCI Accordian */}
          <AccordionGroup
            expanded={false}
            {...GlobalStyles.AccordionGroupStyles(theme)['Accordion'].props}
            caretSize={48}
            closedColor={theme.colors['Medium']}
            icon={'MaterialCommunityIcons/notebook'}
            iconSize={60}
            label={'Canadian Conservation Institute'}
            openColor={theme.colors['Black']}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
                {
                  fontSize: { minWidth: Breakpoints.Desktop, value: 22 },
                  marginLeft: 42,
                }
              ),
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              accessibilityRole={'text'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    fontSize: [
                      { minWidth: Breakpoints.Desktop, value: 22 },
                      { minWidth: Breakpoints.Mobile, value: 16 },
                    ],
                    marginBottom: 10,
                    marginLeft: 50,
                    marginRight: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {
                'Practical advice about issues and questions related to the care, handling, and storage of items.'
              }
            </Text>

            <SupabaseApi.FetchCciGET>
              {({ loading, error, data, refetchCci }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <SimpleStyleFlatList
                    data={fetchData}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) => listData?.id}
                    keyboardShouldPersistTaps={'never'}
                    listKey={'ZU0URq5V'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <Pressable
                          onPress={() => {
                            const handler = async () => {
                              try {
                                /* hidden 'Set Variable' action */
                                /* hidden 'Set Variable' action */
                                /* hidden 'Navigate' action */
                                await WebBrowser.openBrowserAsync(
                                  `${listData?.url}`
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              borderRadius: 15,
                              borderWidth: 1,
                              marginBottom: 5,
                              marginRight: {
                                minWidth: Breakpoints.Laptop,
                                value: 5,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                padding: 10,
                              },
                              dimensions.width
                            )}
                          >
                            <>
                              {!listData?.image ? null : (
                                <Image
                                  resizeMode={'cover'}
                                  {...GlobalStyles.ImageStyles(theme)['Image']
                                    .props}
                                  source={{ uri: `${listData?.image}` }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageStyles(theme)['Image']
                                        .style,
                                      {
                                        borderRadius: 5,
                                        marginRight: 10,
                                        minHeight: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 60,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 60,
                                          },
                                        ],
                                        minWidth: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 60,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 60,
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              accessibilityRole={'button'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    fontFamily: [
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 'System',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 'System',
                                      },
                                    ],
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 18,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 22,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 24,
                                      },
                                    ],
                                    fontWeight: [
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: '400',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: '400',
                                      },
                                    ],
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {listData?.name}
                            </Text>
                          </View>
                        </Pressable>
                      );
                    }}
                    showsVerticalScrollIndicator={true}
                    showsHorizontalScrollIndicator={false}
                    style={StyleSheet.applyWidth(
                      {
                        marginLeft: 50,
                        marginRight: 10,
                        width: { minWidth: Breakpoints.Laptop, value: '50%' },
                      },
                      dimensions.width
                    )}
                  />
                );
              }}
            </SupabaseApi.FetchCciGET>
          </AccordionGroup>
          {/* NPS Accordian */}
          <AccordionGroup
            expanded={false}
            {...GlobalStyles.AccordionGroupStyles(theme)['Accordion'].props}
            caretSize={48}
            closedColor={theme.colors['Medium']}
            icon={'MaterialCommunityIcons/notebook-outline'}
            iconSize={60}
            label={'National Park Service'}
            openColor={theme.colors['Black']}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
                {
                  fontSize: { minWidth: Breakpoints.Desktop, value: 22 },
                  marginLeft: 42,
                }
              ),
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              accessibilityRole={'text'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    fontSize: [
                      { minWidth: Breakpoints.Desktop, value: 22 },
                      { minWidth: Breakpoints.Mobile, value: 16 },
                    ],
                    marginBottom: 10,
                    marginLeft: 50,
                    marginRight: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {
                'Short, focused leaflets of up-to-date museum standards and best practices.'
              }
            </Text>

            <SupabaseApi.FetchNpsGET>
              {({ loading, error, data, refetchNps }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <SimpleStyleFlatList
                    data={fetchData}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) => listData?.id}
                    keyboardShouldPersistTaps={'never'}
                    listKey={'M1d6ZBOp'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <Pressable
                          onPress={() => {
                            const handler = async () => {
                              try {
                                /* hidden 'Set Variable' action */
                                /* hidden 'Set Variable' action */
                                /* hidden 'Navigate' action */
                                await WebBrowser.openBrowserAsync(
                                  `${listData?.url}`
                                );
                                Linking.openURL(`${listData?.url}`);
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              borderRadius: 15,
                              borderWidth: 1,
                              marginBottom: 5,
                              marginRight: {
                                minWidth: Breakpoints.Laptop,
                                value: 5,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                padding: 10,
                              },
                              dimensions.width
                            )}
                          >
                            <>
                              {!listData?.image ? null : (
                                <Image
                                  resizeMode={'cover'}
                                  {...GlobalStyles.ImageStyles(theme)['Image']
                                    .props}
                                  source={{ uri: `${listData?.image}` }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageStyles(theme)['Image']
                                        .style,
                                      {
                                        borderRadius: 5,
                                        marginRight: 10,
                                        minHeight: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 60,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 60,
                                          },
                                        ],
                                        minWidth: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 60,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 60,
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              accessibilityRole={'button'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    fontFamily: [
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 'System',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 'System',
                                      },
                                    ],
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 18,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 22,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 24,
                                      },
                                    ],
                                    fontWeight: [
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: '400',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: '400',
                                      },
                                    ],
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {listData?.name}
                            </Text>
                          </View>
                        </Pressable>
                      );
                    }}
                    showsVerticalScrollIndicator={true}
                    showsHorizontalScrollIndicator={false}
                    style={StyleSheet.applyWidth(
                      {
                        marginLeft: 50,
                        marginRight: 10,
                        width: { minWidth: Breakpoints.Laptop, value: '50%' },
                      },
                      dimensions.width
                    )}
                  />
                );
              }}
            </SupabaseApi.FetchNpsGET>
          </AccordionGroup>
          {/* Agents Accordian */}
          <AccordionGroup
            expanded={false}
            {...GlobalStyles.AccordionGroupStyles(theme)['Accordion'].props}
            caretSize={48}
            closedColor={theme.colors['Medium']}
            icon={'MaterialCommunityIcons/fire'}
            iconSize={60}
            label={'Agents of Deterioration'}
            openColor={theme.colors['Black']}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
                {
                  fontSize: { minWidth: Breakpoints.Desktop, value: 22 },
                  marginLeft: 42,
                }
              ),
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              accessibilityRole={'text'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    fontSize: [
                      { minWidth: Breakpoints.Desktop, value: 22 },
                      { minWidth: Breakpoints.Mobile, value: 16 },
                    ],
                    marginBottom: 10,
                    marginLeft: 50,
                    marginRight: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {
                'Understand the 10 agents of deterioration and how to detect, block, and treat the damage they cause.'
              }
            </Text>

            <SupabaseApi.FetchAgentsofdeteriorationGET>
              {({ loading, error, data, refetchAgentsofdeterioration }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <SimpleStyleFlatList
                    data={fetchData}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) => listData?.id}
                    keyboardShouldPersistTaps={'never'}
                    listKey={'NDIyKnz3'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <Pressable
                          onPress={() => {
                            const handler = async () => {
                              try {
                                /* hidden 'Set Variable' action */
                                /* hidden 'Set Variable' action */
                                /* hidden 'Navigate' action */
                                await WebBrowser.openBrowserAsync(
                                  `${listData?.url}`
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              borderRadius: 15,
                              borderWidth: 1,
                              marginBottom: 5,
                              marginRight: {
                                minWidth: Breakpoints.Laptop,
                                value: 5,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                padding: 10,
                              },
                              dimensions.width
                            )}
                          >
                            <>
                              {!listData?.image ? null : (
                                <Image
                                  resizeMode={'cover'}
                                  {...GlobalStyles.ImageStyles(theme)['Image']
                                    .props}
                                  source={{ uri: `${listData?.image}` }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageStyles(theme)['Image']
                                        .style,
                                      {
                                        borderRadius: 5,
                                        marginRight: 10,
                                        minHeight: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 60,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 60,
                                          },
                                        ],
                                        minWidth: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 60,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 60,
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              accessibilityRole={'button'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    fontFamily: [
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 'System',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 'System',
                                      },
                                    ],
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 18,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 22,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 24,
                                      },
                                    ],
                                    fontWeight: [
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: '400',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: '400',
                                      },
                                    ],
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {listData?.name}
                            </Text>
                          </View>
                        </Pressable>
                      );
                    }}
                    showsVerticalScrollIndicator={true}
                    showsHorizontalScrollIndicator={false}
                    style={StyleSheet.applyWidth(
                      {
                        marginLeft: 50,
                        marginRight: 10,
                        width: { minWidth: Breakpoints.Laptop, value: '50%' },
                      },
                      dimensions.width
                    )}
                  />
                );
              }}
            </SupabaseApi.FetchAgentsofdeteriorationGET>
          </AccordionGroup>
          {/* Conservator Accordian */}
          <AccordionGroup
            expanded={false}
            {...GlobalStyles.AccordionGroupStyles(theme)['Accordion'].props}
            caretSize={48}
            closedColor={theme.colors['Medium']}
            icon={'MaterialCommunityIcons/account-search'}
            iconSize={60}
            label={'Find a Conservator'}
            openColor={theme.colors['Black']}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
                {
                  fontSize: { minWidth: Breakpoints.Desktop, value: 22 },
                  marginLeft: 42,
                }
              ),
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              accessibilityRole={'text'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    fontSize: [
                      { minWidth: Breakpoints.Desktop, value: 22 },
                      { minWidth: Breakpoints.Mobile, value: 16 },
                    ],
                    marginBottom: 10,
                    marginLeft: 50,
                    marginRight: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {
                'Conservators carry out ethical examination, documentation, preventive conservation, and treatment of items.'
              }
            </Text>

            <SupabaseApi.FetchConservatorGET>
              {({ loading, error, data, refetchConservator }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <SimpleStyleFlatList
                    data={fetchData}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) => listData?.id}
                    keyboardShouldPersistTaps={'never'}
                    listKey={'WUmpUuae'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <Pressable
                          onPress={() => {
                            const handler = async () => {
                              try {
                                /* hidden 'Set Variable' action */
                                /* hidden 'Set Variable' action */
                                /* hidden 'Navigate' action */
                                await WebBrowser.openBrowserAsync(
                                  `${listData?.url}`
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            {
                              borderRadius: 15,
                              borderWidth: 1,
                              marginBottom: 5,
                              marginRight: {
                                minWidth: Breakpoints.Laptop,
                                value: 5,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                padding: 10,
                              },
                              dimensions.width
                            )}
                          >
                            <>
                              {!listData?.image ? null : (
                                <Image
                                  resizeMode={'cover'}
                                  {...GlobalStyles.ImageStyles(theme)['Image']
                                    .props}
                                  source={{ uri: `${listData?.image}` }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageStyles(theme)['Image']
                                        .style,
                                      {
                                        borderRadius: 5,
                                        marginRight: 10,
                                        minHeight: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 60,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 60,
                                          },
                                        ],
                                        minWidth: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 60,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 60,
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              accessibilityRole={'button'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    fontFamily: [
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 'System',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 'System',
                                      },
                                    ],
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 18,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 22,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 24,
                                      },
                                    ],
                                    fontWeight: [
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: '400',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: '400',
                                      },
                                    ],
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {listData?.name}
                            </Text>
                          </View>
                        </Pressable>
                      );
                    }}
                    showsVerticalScrollIndicator={true}
                    showsHorizontalScrollIndicator={false}
                    style={StyleSheet.applyWidth(
                      {
                        marginLeft: 50,
                        marginRight: 10,
                        width: { minWidth: Breakpoints.Laptop, value: '50%' },
                      },
                      dimensions.width
                    )}
                  />
                );
              }}
            </SupabaseApi.FetchConservatorGET>
          </AccordionGroup>
        </AccordionGroup>
        {/* Contact Accordion */}
        <AccordionGroup
          expanded={false}
          {...GlobalStyles.AccordionGroupStyles(theme)['Accordion'].props}
          caretSize={48}
          closedColor={theme.colors['Medium']}
          icon={'MaterialIcons/alternate-email'}
          iconSize={60}
          label={'Contact Us'}
          openColor={theme.colors['Black']}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
              {
                fontSize: [
                  { minWidth: Breakpoints.BigScreen, value: 22 },
                  { minWidth: Breakpoints.Desktop, value: 22 },
                ],
              }
            ),
            dimensions.width
          )}
        >
          {/* Hours of Operation */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            accessibilityRole={'text'}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                fontSize: [
                  { minWidth: Breakpoints.Mobile, value: 16 },
                  { minWidth: Breakpoints.Desktop, value: 22 },
                ],
                marginLeft: 50,
                marginRight: 10,
              }),
              dimensions.width
            )}
          >
            {'Hours of Operation:'}
          </Text>
          {/* Hours Fetch */}
          <SupabaseApi.FetchHoursGET>
            {({ loading, error, data, refetchHours }) => {
              const hoursFetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <SimpleStyleFlatList
                  data={hoursFetchData}
                  horizontal={false}
                  inverted={false}
                  keyExtractor={(listData, index) => listData?.id}
                  keyboardShouldPersistTaps={'never'}
                  listKey={'bbz95EC7'}
                  nestedScrollEnabled={false}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <>
                        {/* Hours */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          accessibilityRole={'text'}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                fontFamily: 'System',
                                fontSize: [
                                  { minWidth: Breakpoints.Desktop, value: 22 },
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                ],
                                fontWeight: '400',
                                marginBottom: 2,
                                marginTop: 2,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {listData?.description}
                        </Text>
                      </>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  style={StyleSheet.applyWidth(
                    { marginLeft: 55, marginRight: 10 },
                    dimensions.width
                  )}
                />
              );
            }}
          </SupabaseApi.FetchHoursGET>
          {/* Contacts Fetch */}
          <SupabaseApi.FetchContactusGET>
            {({ loading, error, data, refetchContactus }) => {
              const contactsFetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <SimpleStyleFlatList
                  data={contactsFetchData}
                  horizontal={false}
                  inverted={false}
                  keyExtractor={(listData, index) => listData?.id}
                  keyboardShouldPersistTaps={'never'}
                  listKey={'ISCaPMXf'}
                  nestedScrollEnabled={false}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderRadius: 15,
                            borderWidth: 1,
                            marginBottom: 5,
                            marginRight: 10,
                            padding: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Position */}
                        <>
                          {!listData?.name ? null : (
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              accessibilityRole={'text'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    fontFamily: [
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 'System',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 'System',
                                      },
                                    ],
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 18,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 22,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 24,
                                      },
                                    ],
                                    fontWeight: [
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: '400',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: '400',
                                      },
                                    ],
                                    textDecorationLine: 'underline',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {listData?.name}
                            </Text>
                          )}
                        </>
                        {/* Phone */}
                        <>
                          {!listData?.phone ? null : (
                            <Link
                              accessible={true}
                              onPress={() => {
                                try {
                                  Linking.openURL(`tel:+${listData?.phone}`);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.LinkStyles(theme)['Link'].props}
                              accessibilityRole={'link'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.LinkStyles(theme)['Link'].style,
                                  {
                                    color: theme.colors['Black'],
                                    fontFamily: [
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 'System',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 'System',
                                      },
                                    ],
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 18,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 22,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 24,
                                      },
                                    ],
                                    fontWeight: [
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: '400',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: '400',
                                      },
                                    ],
                                  }
                                ),
                                dimensions.width
                              )}
                              title={`Phone: ${listData?.phone}`}
                            />
                          )}
                        </>
                        {/* Email */}
                        <>
                          {!listData?.email ? null : (
                            <Link
                              accessible={true}
                              onPress={() => {
                                try {
                                  Linking.openURL(
                                    `mailto:${listData?.email}?subject=Swift History Enquiry`
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.LinkStyles(theme)['Link'].props}
                              accessibilityRole={'link'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.LinkStyles(theme)['Link'].style,
                                  {
                                    color: theme.colors['Black'],
                                    fontFamily: [
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 'System',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 'System',
                                      },
                                    ],
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 18,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 22,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 24,
                                      },
                                    ],
                                    fontWeight: [
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: '400',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: '400',
                                      },
                                    ],
                                  }
                                ),
                                dimensions.width
                              )}
                              title={`Email: ${listData?.email}`}
                            />
                          )}
                        </>
                      </View>
                    );
                  }}
                  showsVerticalScrollIndicator={true}
                  showsHorizontalScrollIndicator={false}
                  style={StyleSheet.applyWidth(
                    { marginLeft: 50, marginRight: 10 },
                    dimensions.width
                  )}
                />
              );
            }}
          </SupabaseApi.FetchContactusGET>
        </AccordionGroup>
        {/* Policies Accordion */}
        <AccordionGroup
          expanded={false}
          {...GlobalStyles.AccordionGroupStyles(theme)['Accordion'].props}
          caretSize={48}
          closedColor={theme.colors['Medium']}
          icon={'Ionicons/shield'}
          iconSize={60}
          label={'Policies'}
          openColor={theme.colors['Black']}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.AccordionGroupStyles(theme)['Accordion'].style,
              { fontSize: { minWidth: Breakpoints.Desktop, value: 22 } }
            ),
            dimensions.width
          )}
        >
          {/* Policies Fetch */}
          <SupabaseApi.FetchPoliciesGET>
            {({ loading, error, data, refetchPolicies }) => {
              const policiesFetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <SimpleStyleFlatList
                  data={policiesFetchData}
                  horizontal={false}
                  inverted={false}
                  keyExtractor={(listData, index) => listData?.id}
                  keyboardShouldPersistTaps={'never'}
                  listKey={'5v5Zm6vg'}
                  nestedScrollEnabled={false}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <>
                        {/* Policy Title */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          accessibilityRole={'text'}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                fontFamily: 'System',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                  { minWidth: Breakpoints.Desktop, value: 22 },
                                ],
                                fontWeight: '700',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {listData?.name}
                        </Text>
                        {/* Policy */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          accessibilityRole={'text'}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                fontSize: [
                                  { minWidth: Breakpoints.Desktop, value: 22 },
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                ],
                                marginBottom: 20,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {listData?.description}
                        </Text>
                      </>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  style={StyleSheet.applyWidth(
                    { marginLeft: 50, marginRight: 10 },
                    dimensions.width
                  )}
                />
              );
            }}
          </SupabaseApi.FetchPoliciesGET>
        </AccordionGroup>
      </View>
      <Utils.CustomCodeErrorBoundary>
        <CustomCode.ChangeStackNavigation navigation={props.navigation} />
      </Utils.CustomCodeErrorBoundary>
    </ScreenContainer>
  );
};

export default withTheme(SwiftHistoryResourcesScreen);
