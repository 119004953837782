import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SupabaseApi from '../apis/SupabaseApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Icon,
  Pressable,
  ScreenContainer,
  Shadow,
  Spacer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, Text, View } from 'react-native';

const IndexScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [ackText, setAckText] = React.useState('');
  const [translationText, setTranslationText] = React.useState('');
  const [welcomeText, setWelcomeText] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const welcome_response = (await SupabaseApi.welcomeGET(Constants))
          ?.json;
        const translations_response = (
          await SupabaseApi.welcometranslationsGET(Constants)
        )?.json;
        const acknowledgement_response = (
          await SupabaseApi.resourcesLandAckGET(Constants)
        )?.json;
        setWelcomeText(welcome_response && welcome_response[0].description);
        setTranslationText(
          translations_response && translations_response[0].description
        );
        setAckText(
          acknowledgement_response && acknowledgement_response[0].description
        );
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          alignSelf: 'center',
          backgroundColor: theme.colors['White'],
          justifyContent: 'space-evenly',
          padding: '5%',
        },
        dimensions.width
      )}
    >
      {/* Top */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: { minWidth: Breakpoints.BigScreen, value: 'center' },
            alignSelf: { minWidth: Breakpoints.BigScreen, value: 'auto' },
            marginBottom: 10,
          },
          dimensions.width
        )}
      >
        {/* Header */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: [
                { minWidth: Breakpoints.Mobile, value: 'center' },
                { minWidth: Breakpoints.BigScreen, value: 'center' },
              ],
              alignSelf: { minWidth: Breakpoints.BigScreen, value: 'auto' },
              flexDirection: 'row',
              justifyContent: {
                minWidth: Breakpoints.BigScreen,
                value: 'flex-start',
              },
            },
            dimensions.width
          )}
        >
          {/* Left */}
          <View>
            {/* SCM Logo */}
            <Image
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              resizeMode={'center'}
              source={Images.SCMLogo}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  {
                    height: [
                      { minWidth: Breakpoints.BigScreen, value: 200 },
                      { minWidth: Breakpoints.Mobile, value: 100 },
                      { minWidth: Breakpoints.Tablet, value: 150 },
                    ],
                    maxHeight: [
                      { minWidth: Breakpoints.Mobile, value: 100 },
                      { minWidth: Breakpoints.Tablet, value: 150 },
                    ],
                    maxWidth: [
                      { minWidth: Breakpoints.Mobile, value: 100 },
                      { minWidth: Breakpoints.Tablet, value: 150 },
                    ],
                    minHeight: [
                      { minWidth: Breakpoints.Mobile, value: 100 },
                      { minWidth: Breakpoints.Tablet, value: 150 },
                    ],
                    minWidth: [
                      { minWidth: Breakpoints.Mobile, value: 100 },
                      { minWidth: Breakpoints.Tablet, value: 150 },
                    ],
                    width: [
                      { minWidth: Breakpoints.BigScreen, value: 200 },
                      { minWidth: Breakpoints.Mobile, value: 100 },
                      { minWidth: Breakpoints.Tablet, value: 150 },
                    ],
                  }
                ),
                dimensions.width
              )}
            />
          </View>
          <Spacer bottom={8} left={8} right={8} top={8} />
          {/* Right */}
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Translation Placeholder */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              accessibilityRole={'text'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: theme.colors['Black'],
                    fontFamily: 'System',
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 16 },
                      { minWidth: Breakpoints.Desktop, value: 22 },
                    ],
                    fontStyle: 'italic',
                    fontWeight: '400',
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {translationText}
            </Text>
            {/* Welcome Placeholder */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  accessibilityRole={'text'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors['Black'],
                        fontFamily: 'System',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Desktop, value: 22 },
                        ],
                        fontWeight: '400',
                        marginTop: { minWidth: Breakpoints.Laptop, value: 15 },
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                  suppressHighlighting={true}
                >
                  {welcomeText}
                </Text>
              )}
            </>
          </View>
        </View>
        <Spacer bottom={8} left={8} right={8} top={8} />
        {/* WelcomeMobile */}
        <>
          {dimensions.width >= Breakpoints.Laptop ? null : (
            <View>
              {/* Welcome Placeholder 2 */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                accessibilityRole={'text'}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: theme.colors['Black'],
                      fontFamily: 'System',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 16 },
                        { minWidth: Breakpoints.Desktop, value: 22 },
                      ],
                      fontWeight: '400',
                      marginTop: { minWidth: Breakpoints.Laptop, value: 15 },
                      textAlign: 'center',
                    }
                  ),
                  dimensions.width
                )}
                suppressHighlighting={true}
              >
                {welcomeText}
              </Text>
            </View>
          )}
        </>
      </View>
      {/* Buttons */}
      <View
        style={StyleSheet.applyWidth(
          {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
          },
          dimensions.width
        )}
      >
        {/* Places */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginTop: { minWidth: Breakpoints.Desktop, value: 0 },
              padding: 10,
            },
            dimensions.width
          )}
        >
          {/* Places */}
          <View>
            {/* Places Pressable */}
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('StackNavigator', {
                    screen: 'SwiftHistoryPlacesScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Places Shadow */}
              <Shadow
                offsetX={0}
                offsetY={0}
                paintInside={true}
                showShadowCornerBottomEnd={true}
                showShadowCornerBottomStart={true}
                showShadowCornerTopEnd={true}
                showShadowCornerTopStart={true}
                showShadowSideBottom={true}
                showShadowSideEnd={true}
                showShadowSideStart={true}
                showShadowSideTop={true}
                distance={5}
                stretch={false}
              >
                {/* Places View */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['White'],
                      borderColor: theme.colors['Black'],
                      borderRadius: 10,
                      borderWidth: 2,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon 3 */}
                  <Icon
                    color={theme.colors['Black']}
                    name={'MaterialCommunityIcons/office-building'}
                    size={100}
                  />
                  {/* Places Text */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    accessibilityRole={'button'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          alignSelf: 'center',
                          color: theme.colors['Black'],
                          fontFamily: 'System',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 16 },
                            { minWidth: Breakpoints.Desktop, value: 22 },
                          ],
                          fontWeight: '400',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Places'}
                  </Text>
                </View>
              </Shadow>
            </Pressable>
          </View>
        </View>
        {/* People */}
        <View style={StyleSheet.applyWidth({ padding: 10 }, dimensions.width)}>
          {/* People */}
          <View>
            {/* People Pressable */}
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('StackNavigator', {
                    screen: 'SwiftHistoryPeopleScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* People Shadow */}
              <Shadow
                offsetX={0}
                offsetY={0}
                paintInside={true}
                showShadowCornerBottomEnd={true}
                showShadowCornerBottomStart={true}
                showShadowCornerTopEnd={true}
                showShadowCornerTopStart={true}
                showShadowSideBottom={true}
                showShadowSideEnd={true}
                showShadowSideStart={true}
                showShadowSideTop={true}
                distance={5}
              >
                {/* People View */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['White'],
                      borderColor: theme.colors['Black'],
                      borderRadius: 10,
                      borderWidth: 2,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon 2 */}
                  <Icon
                    color={theme.colors['Black']}
                    name={'Ionicons/person'}
                    size={100}
                  />
                  {/* People Text */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    accessibilityRole={'button'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          alignSelf: 'center',
                          color: theme.colors['Black'],
                          fontFamily: 'System',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 16 },
                            { minWidth: Breakpoints.Desktop, value: 22 },
                          ],
                          fontWeight: '400',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'People'}
                  </Text>
                </View>
              </Shadow>
            </Pressable>
          </View>
        </View>
        {/* Map */}
        <View style={StyleSheet.applyWidth({ padding: 10 }, dimensions.width)}>
          {/* Map */}
          <View>
            {/* Map Pressable */}
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('StackNavigator', {
                    screen: 'SwiftHistoryMapScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Map Shadow */}
              <Shadow
                offsetX={0}
                offsetY={0}
                paintInside={true}
                showShadowCornerBottomEnd={true}
                showShadowCornerBottomStart={true}
                showShadowCornerTopEnd={true}
                showShadowCornerTopStart={true}
                showShadowSideBottom={true}
                showShadowSideEnd={true}
                showShadowSideStart={true}
                showShadowSideTop={true}
                distance={5}
              >
                {/* Map View */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors['White'],
                      borderRadius: 10,
                      borderWidth: [
                        { minWidth: Breakpoints.Mobile, value: 2 },
                        { minWidth: Breakpoints.BigScreen, value: 2 },
                      ],
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon 4 */}
                  <Icon
                    color={theme.colors['Black']}
                    name={'Foundation/map'}
                    size={100}
                  />
                  {/* Map Text */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    accessibilityRole={'button'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          alignSelf: 'center',
                          color: theme.colors['Black'],
                          fontFamily: 'System',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 16 },
                            { minWidth: Breakpoints.Desktop, value: 22 },
                          ],
                          fontWeight: '400',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Map'}
                  </Text>
                </View>
              </Shadow>
            </Pressable>
          </View>
        </View>
        {/* Activities */}
        <View style={StyleSheet.applyWidth({ padding: 10 }, dimensions.width)}>
          {/* Activities */}
          <View>
            {/* Activities Pressable */}
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('StackNavigator', {
                    screen: 'SwiftHistoryActivitiesScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Resources Shadow */}
              <Shadow
                offsetX={0}
                offsetY={0}
                paintInside={true}
                showShadowCornerBottomEnd={true}
                showShadowCornerBottomStart={true}
                showShadowCornerTopEnd={true}
                showShadowCornerTopStart={true}
                showShadowSideBottom={true}
                showShadowSideEnd={true}
                showShadowSideStart={true}
                showShadowSideTop={true}
                distance={5}
              >
                {/* Resources View */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: {
                        minWidth: Breakpoints.BigScreen,
                        value: 'flex-start',
                      },
                      alignItems: {
                        minWidth: Breakpoints.BigScreen,
                        value: 'center',
                      },
                      alignSelf: {
                        minWidth: Breakpoints.BigScreen,
                        value: 'auto',
                      },
                      backgroundColor: theme.colors['White'],
                      borderColor: theme.colors['Black'],
                      borderRadius: 10,
                      borderWidth: 2,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Black']}
                    name={'MaterialCommunityIcons/drawing-box'}
                    size={100}
                  />
                  {/* Resources Text */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    accessibilityRole={'button'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          alignSelf: 'center',
                          color: theme.colors['Black'],
                          fontFamily: 'System',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 16 },
                            { minWidth: Breakpoints.Desktop, value: 22 },
                          ],
                          fontWeight: '400',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Activities'}
                  </Text>
                </View>
              </Shadow>
            </Pressable>
          </View>
        </View>
        {/* Media */}
        <View style={StyleSheet.applyWidth({ padding: 10 }, dimensions.width)}>
          {/* Media */}
          <View>
            {/* Media Pressable */}
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('StackNavigator', {
                    screen: 'SwiftHistoryMediaScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Resources Shadow */}
              <Shadow
                offsetX={0}
                offsetY={0}
                paintInside={true}
                showShadowCornerBottomEnd={true}
                showShadowCornerBottomStart={true}
                showShadowCornerTopEnd={true}
                showShadowCornerTopStart={true}
                showShadowSideBottom={true}
                showShadowSideEnd={true}
                showShadowSideStart={true}
                showShadowSideTop={true}
                distance={5}
              >
                {/* Resources View */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: {
                        minWidth: Breakpoints.BigScreen,
                        value: 'flex-start',
                      },
                      alignItems: {
                        minWidth: Breakpoints.BigScreen,
                        value: 'center',
                      },
                      alignSelf: {
                        minWidth: Breakpoints.BigScreen,
                        value: 'auto',
                      },
                      backgroundColor: theme.colors['White'],
                      borderColor: theme.colors['Black'],
                      borderRadius: 10,
                      borderWidth: 2,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Black']}
                    name={'Feather/film'}
                    size={100}
                  />
                  {/* Resources Text */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    accessibilityRole={'button'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          alignSelf: 'center',
                          color: theme.colors['Black'],
                          fontFamily: 'System',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 16 },
                            { minWidth: Breakpoints.Desktop, value: 22 },
                          ],
                          fontWeight: '400',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Media'}
                  </Text>
                </View>
              </Shadow>
            </Pressable>
          </View>
        </View>
        {/* Resources */}
        <View style={StyleSheet.applyWidth({ padding: 10 }, dimensions.width)}>
          {/* Resources */}
          <View>
            {/* Resources Pressable */}
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('StackNavigator', {
                    screen: 'SwiftHistoryResourcesScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Resources Shadow */}
              <Shadow
                offsetX={0}
                offsetY={0}
                paintInside={true}
                showShadowCornerBottomEnd={true}
                showShadowCornerBottomStart={true}
                showShadowCornerTopEnd={true}
                showShadowCornerTopStart={true}
                showShadowSideBottom={true}
                showShadowSideEnd={true}
                showShadowSideStart={true}
                showShadowSideTop={true}
                distance={5}
              >
                {/* Resources View */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: {
                        minWidth: Breakpoints.BigScreen,
                        value: 'flex-start',
                      },
                      alignItems: {
                        minWidth: Breakpoints.BigScreen,
                        value: 'center',
                      },
                      alignSelf: {
                        minWidth: Breakpoints.BigScreen,
                        value: 'auto',
                      },
                      backgroundColor: theme.colors['White'],
                      borderColor: theme.colors['Black'],
                      borderRadius: 10,
                      borderWidth: 2,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Black']}
                    name={'FontAwesome/book'}
                    size={100}
                  />
                  {/* Resources Text */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    accessibilityRole={'button'}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          alignSelf: 'center',
                          color: theme.colors['Black'],
                          fontFamily: 'System',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 16 },
                            { minWidth: Breakpoints.Desktop, value: 22 },
                          ],
                          fontWeight: '400',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Resources'}
                  </Text>
                </View>
              </Shadow>
            </Pressable>
          </View>
        </View>
      </View>
      {/* Bottom */}
      <View style={StyleSheet.applyWidth({ marginTop: 10 }, dimensions.width)}>
        {/* Land Ack Placeholder */}
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          accessibilityRole={'text'}
          disabled={false}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              alignSelf: 'center',
              color: theme.colors['Black'],
              fontFamily: 'System',
              fontSize: [
                { minWidth: Breakpoints.Mobile, value: 16 },
                { minWidth: Breakpoints.Desktop, value: 22 },
              ],
              fontWeight: '400',
              textAlign: 'center',
            }),
            dimensions.width
          )}
          suppressHighlighting={true}
        >
          {ackText}
        </Text>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(IndexScreen);
