import { systemWeights } from 'react-native-typography';
export default {
  disabledOpacity: 0.5,
  roundness: 6,
  colors: {
    Black: 'rgb(0, 0, 0)',
    Divider: 'rgba(234, 237, 242, 1)',
    Error: 'rgba(255, 69, 100, 1)',
    Light: 'rgba(165, 173, 183, 1)',
    'Light Inverse': 'rgba(255, 255, 255, 0.68)',
    Medium: 'rgba(70, 78, 88, 1)',
    'Medium Inverse': 'rgba(255, 255, 255, 0.87)',
    'Museum Brown': 'rgb(89, 74, 67)',
    Primary: 'rgba(90, 69, 255, 1)',
    Secondary: 'rgba(59, 201, 234, 1)',
    'Strong Inverse': 'rgba(255, 255, 255, 1)',
    Surface: 'rgba(255, 255, 255, 1)',
    White: 'rgb(255, 255, 255)',
    black: 'rgb(0, 0, 0)',
    divider: 'rgba(234, 237, 242, 1)',
    error: 'rgba(255, 69, 100, 1)',
    light: 'rgba(165, 173, 183, 1)',
    lightInverse: 'rgba(255, 255, 255, 0.68)',
    medium: 'rgba(70, 78, 88, 1)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    museumBrown: 'rgb(89, 74, 67)',
    primary: 'rgba(90, 69, 255, 1)',
    secondary: 'rgba(59, 201, 234, 1)',
    strongInverse: 'rgba(255, 255, 255, 1)',
    surface: 'rgba(255, 255, 255, 1)',
    white: 'rgb(255, 255, 255)',
  },
  typography: {
    body1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    body2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
    button: {
      ...systemWeights.bold,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 16,
    },
    caption: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: 16,
    },
    headline1: {
      ...systemWeights.bold,
      fontSize: 60,
      letterSpacing: 0,
      lineHeight: 71,
    },
    headline2: {
      ...systemWeights.bold,
      fontSize: 48,
      letterSpacing: 0,
      lineHeight: 58,
    },
    headline3: {
      ...systemWeights.bold,
      fontSize: 34,
      letterSpacing: 0,
      lineHeight: 40,
    },
    headline4: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: 34,
    },
    headline5: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: 26,
    },
    headline6: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 24,
    },
    overline: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 2,
      lineHeight: 16,
    },
    subtitle1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    subtitle2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
    custom100: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom101: {
      ...systemWeights.bold,
      fontSize: 36,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom102: {
      ...systemWeights.bold,
      fontSize: 36,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom103: {
      ...systemWeights.bold,
      fontSize: 36,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom104: {
      ...systemWeights.bold,
      fontSize: 36,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom105: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom106: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom107: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom108: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom109: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom110: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom111: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom112: {
      ...systemWeights.bold,
      fontSize: 36,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom113: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom114: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom115: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 1,
      lineHeight: 18,
    },
    custom116: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 1,
      lineHeight: 18,
    },
    custom117: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom118: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 1,
      lineHeight: 50,
    },
    custom119: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 12,
      lineHeight: 50,
    },
    custom120: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: 50,
    },
    custom121: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 50,
    },
    custom122: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 50,
    },
    custom123: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom124: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: 36,
    },
    custom125: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: 36,
    },
    custom126: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 50,
    },
    custom127: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 50,
    },
    custom128: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom129: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom130: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom131: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom132: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom133: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 38,
    },
    custom134: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 0,
    },
    custom135: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 38,
    },
    custom136: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 50,
    },
    custom137: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 0,
    },
    custom138: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 16,
    },
    custom139: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 24,
    },
    custom14: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom15: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 18,
    },
    custom16: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom17: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom18: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom19: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom20: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom21: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom22: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom23: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom24: {
      ...systemWeights.thin,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom25: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom26: {
      ...systemWeights.regular,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom27: {
      ...systemWeights.regular,
      fontSize: 160,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom28: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom29: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom30: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 26,
    },
    custom31: {
      ...systemWeights.regular,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom32: {
      ...systemWeights.regular,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 26,
    },
    custom33: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 26,
    },
    custom34: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 28,
    },
    custom35: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 28,
    },
    custom36: {
      ...systemWeights.regular,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom37: {
      ...systemWeights.regular,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 28,
    },
    custom38: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 28,
    },
    custom39: {
      ...systemWeights.regular,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom40: {
      ...systemWeights.regular,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 28,
    },
    custom41: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 28,
    },
    custom42: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom43: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom44: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 1,
      lineHeight: 28,
    },
    custom45: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom46: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom47: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom48: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom49: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom50: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom51: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom52: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom53: {
      ...systemWeights.bold,
      fontSize: 30,
      letterSpacing: 1,
      lineHeight: 28,
    },
    custom54: {
      ...systemWeights.bold,
      fontSize: 30,
      letterSpacing: 1,
      lineHeight: 42,
    },
    custom55: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom56: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom57: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom58: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom59: {
      ...systemWeights.thin,
      fontSize: 30,
      letterSpacing: 1,
      lineHeight: 42,
    },
    custom60: {
      ...systemWeights.bold,
      fontSize: 30,
      letterSpacing: 1,
      lineHeight: 42,
    },
    custom61: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom62: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom63: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom64: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom65: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom66: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom67: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom68: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom69: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom70: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom71: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom72: {
      ...systemWeights.bold,
      fontSize: 36,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom73: {
      ...systemWeights.bold,
      fontSize: 36,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom74: {
      ...systemWeights.bold,
      fontSize: 36,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom75: {
      ...systemWeights.bold,
      fontSize: 36,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom76: {
      ...systemWeights.bold,
      fontSize: 31,
      letterSpacing: 1,
      lineHeight: 42,
    },
    custom77: {
      ...systemWeights.bold,
      fontSize: 30,
      letterSpacing: 1,
      lineHeight: 42,
    },
    custom78: {
      ...systemWeights.bold,
      fontSize: 30,
      letterSpacing: 1,
      lineHeight: 41,
    },
    custom79: {
      ...systemWeights.bold,
      fontSize: 30,
      letterSpacing: 1,
      lineHeight: 42,
    },
    custom80: {
      ...systemWeights.bold,
      fontSize: 36,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom81: {
      ...systemWeights.bold,
      fontSize: 36,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom82: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 30,
    },
    custom83: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom84: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 50,
    },
    custom85: {
      ...systemWeights.regular,
      fontSize: 50,
      letterSpacing: 1,
      lineHeight: 50,
    },
    custom86: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 50,
    },
    custom87: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 48,
    },
    custom88: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 48,
    },
    custom89: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 48,
    },
    custom90: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 48,
    },
    custom91: {
      ...systemWeights.regular,
      fontSize: 24,
      letterSpacing: 1,
      lineHeight: 48,
    },
    custom92: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 1,
      lineHeight: 48,
    },
    custom93: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 1,
      lineHeight: 12,
    },
    custom94: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 1,
      lineHeight: 24,
    },
    custom95: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 1,
      lineHeight: 18,
    },
    custom96: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 42,
    },
    custom97: {
      ...systemWeights.bold,
      fontSize: 36,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom98: {
      ...systemWeights.bold,
      fontSize: 36,
      letterSpacing: 1,
      lineHeight: 36,
    },
    custom99: {
      ...systemWeights.bold,
      fontSize: 36,
      letterSpacing: 1,
      lineHeight: 36,
    },
  },
};
