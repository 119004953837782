import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/Draftbit.js';
import LinkingConfiguration from './LinkingConfiguration.js';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import React from 'react';
import Breakpoints from './utils/Breakpoints';
import useWindowDimensions from './utils/useWindowDimensions';

import IndexScreen from './screens/IndexScreen';
import SwiftHistoryActivitiesScreen from './screens/SwiftHistoryActivitiesScreen';
import SwiftHistoryBrowserScreen from './screens/SwiftHistoryBrowserScreen';
import SwiftHistoryInformationScreen from './screens/SwiftHistoryInformationScreen';
import SwiftHistoryMapScreen from './screens/SwiftHistoryMapScreen';
import SwiftHistoryMediaScreen from './screens/SwiftHistoryMediaScreen';
import SwiftHistoryPeopleScreen from './screens/SwiftHistoryPeopleScreen';
import SwiftHistoryPlacesScreen from './screens/SwiftHistoryPlacesScreen';
import SwiftHistoryResourcesScreen from './screens/SwiftHistoryResourcesScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

function StackNavigator() {
  return (
    <Stack.Navigator
      initialRouteName="IndexScreen"
      presentation="card"
      screenOptions={({ navigation }) => ({
        headerLeft: ({ tintColor, canGoBack }) =>
          canGoBack ? (
            <View style={[styles.headerContainer, styles.headerContainerLeft]}>
              <Icon
                name=""
                size={Platform.OS === 'ios' ? 21 : 24}
                color={theme.colors['White']}
                style={[styles.headerIcon, styles.headerIconLeft]}
              />
            </View>
          ) : (
            <View style={[styles.headerContainer, styles.headerContainerLeft]}>
              <Icon
                name=""
                size={Platform.OS === 'ios' ? 21 : 24}
                color={theme.colors['White']}
                style={[styles.headerIcon, styles.headerIconLeft]}
              />
            </View>
          ),
        headerRight: ({ tintColor }) => (
          <View style={[styles.headerContainer, styles.headerContainerRight]}>
            <Icon
              name=""
              size={Platform.OS === 'ios' ? 21 : 24}
              color={theme.colors['White']}
              style={[styles.headerIcon, styles.headerIconRight]}
            />
          </View>
        ),
        headerStyle: {
          backgroundColor: theme.colors['Museum Brown'],
          borderBottomColor: 'transparent',
        },
        headerTintColor: theme.colors['White'],
        headerTitle: 'Swift History',
        headerTitleAlign: 'center',
        headerTitleAllowFontScaling: true,
        headerTitleStyle: {
          fontFamily: 'Alata_400Regular',
          fontSize: 36,
          lineHeight: 48,
        },
      })}
    >
      <Stack.Screen
        name="SwiftHistoryResourcesScreen"
        component={SwiftHistoryResourcesScreen}
        options={({ navigation }) => ({
          title: 'Swift History - Resources',
        })}
      />
      <Stack.Screen
        name="SwiftHistoryMapScreen"
        component={SwiftHistoryMapScreen}
        options={({ navigation }) => ({
          title: 'Swift History - Map',
        })}
      />
      <Stack.Screen
        name="SwiftHistoryPlacesScreen"
        component={SwiftHistoryPlacesScreen}
        options={({ navigation }) => ({
          title: 'Swift History - Places',
        })}
      />
      <Stack.Screen
        name="IndexScreen"
        component={IndexScreen}
        options={({ navigation }) => ({
          headerTitle: 'Swift History',
          title: 'Index',
        })}
      />
      <Stack.Screen
        name="SwiftHistoryBrowserScreen"
        component={SwiftHistoryBrowserScreen}
        options={({ navigation }) => ({
          title: 'Swift History - Browser',
        })}
      />
      <Stack.Screen
        name="SwiftHistoryActivitiesScreen"
        component={SwiftHistoryActivitiesScreen}
        options={({ navigation }) => ({
          title: 'Swift History - Activities',
        })}
      />
      <Stack.Screen
        name="SwiftHistoryMediaScreen"
        component={SwiftHistoryMediaScreen}
        options={({ navigation }) => ({
          title: 'Swift History - Media',
        })}
      />
      <Stack.Screen
        name="SwiftHistoryInformationScreen"
        component={SwiftHistoryInformationScreen}
        options={({ navigation }) => ({
          title: 'Swift History - Information',
        })}
      />
      <Stack.Screen
        name="SwiftHistoryPeopleScreen"
        component={SwiftHistoryPeopleScreen}
        options={({ navigation }) => ({
          title: 'Swift History - People',
        })}
      />
    </Stack.Navigator>
  );
}

export default function RootAppNavigator() {
  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#FFFFFF',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="StackNavigator"
        presentation="card"
        screenOptions={({ navigation }) => ({
          headerLeft: ({ tintColor, canGoBack }) =>
            canGoBack ? (
              <View
                style={[styles.headerContainer, styles.headerContainerLeft]}
              >
                <Icon
                  name="AntDesign/back"
                  size={Platform.OS === 'ios' ? 21 : 24}
                  color={theme.colors['White']}
                  style={[styles.headerIcon, styles.headerIconLeft]}
                />
              </View>
            ) : null,
          headerRight: ({ tintColor }) => (
            <View style={[styles.headerContainer, styles.headerContainerRight]}>
              <Icon
                name="MaterialIcons/house"
                size={Platform.OS === 'ios' ? 21 : 24}
                color={theme.colors['White']}
                style={[styles.headerIcon, styles.headerIconRight]}
              />
            </View>
          ),
          headerShown: false,
          headerStyle: {
            backgroundColor: theme.colors['Museum Brown'],
            borderBottomColor: 'transparent',
          },
          headerTintColor: theme.colors['White'],
          headerTitle: 'Swift History',
          headerTitleAlign: 'center',
          headerTitleAllowFontScaling: true,
          headerTitleStyle: {
            fontFamily: 'Alata_400Regular',
            fontSize: 24,
            lineHeight: 36,
          },
        })}
      >
        <Stack.Screen name="StackNavigator" component={StackNavigator} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerContainerRight: Platform.select({ ios: { marginRight: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
  headerIconRight: Platform.select({ ios: { marginLeft: 6 } }),
});
