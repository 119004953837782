import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const activityGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?status=${encodeQueryParam(
      `eq.active`
    )}&or=${encodeQueryParam(
      `(type.eq.activity,type.eq.jigsaw,type.eq.sliding,type.eq.wordsearch)`
    )}&order=${encodeQueryParam(`name,type`)}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useActivityGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['activities', args],
    () => activityGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchActivityGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useActivityGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchActivity: refetch });
};

export const agentsofdeteriorationGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.treasure`)}&description=${encodeQueryParam(
      `eq.Canadian Conservation Institute Agents of Deterioration`
    )}&order=name`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useAgentsofdeteriorationGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['agents', args],
    () => agentsofdeteriorationGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchAgentsofdeteriorationGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useAgentsofdeteriorationGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchAgentsofdeterioration: refetch,
  });
};

export const cciGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.treasure`)}&or=${encodeQueryParam(
      `(description.eq.Canadian Conservation Institute Notes,description.eq.Canadian Conservation Institute Technical Bulletins)`
    )}&order=${encodeQueryParam(`description,name`)}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useCciGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['ccis', args], () => cciGET(Constants, args, handlers), {
    refetchInterval,
  });
};

export const FetchCciGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCciGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCci: refetch });
};

export const conservatorGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.treasure`)}&description=${encodeQueryParam(
      `eq.Find A Conservator`
    )}&order=${encodeQueryParam(`name.desc`)}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useConservatorGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['conservators', args],
    () => conservatorGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchConservatorGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useConservatorGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchConservator: refetch });
};

export const contactusGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/contacts?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.staff`)}&order=${encodeQueryParam(
      `id.desc`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useContactusGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['contactuses', args],
    () => contactusGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchContactusGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useContactusGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchContactus: refetch });
};

export const databaseArchivesGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?id=${encodeQueryParam(
      `eq.d0f7e2a1-df93-48db-a790-5fdad4a406ad`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useDatabaseArchivesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['databaseArchive', args],
    () => databaseArchivesGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['databaseArchives']),
    }
  );
};

export const FetchDatabaseArchivesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDatabaseArchivesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDatabaseArchives: refetch });
};

export const databaseLibraryGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?id=${encodeQueryParam(
      `eq.1c3621d2-5576-425d-934d-011324a45502`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useDatabaseLibraryGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['databaseLibrary', args],
    () => databaseLibraryGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['databaseLibraries']),
    }
  );
};

export const FetchDatabaseLibraryGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDatabaseLibraryGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDatabaseLibrary: refetch });
};

export const databaseMuseumGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?id=${encodeQueryParam(
      `eq.62e19e24-d101-446e-89f4-07459c480039`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useDatabaseMuseumGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['databaseMuseum', args],
    () => databaseMuseumGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['databaseMuseums']),
    }
  );
};

export const FetchDatabaseMuseumGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDatabaseMuseumGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDatabaseMuseum: refetch });
};

export const databasesGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.database`)}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useDatabasesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['databases', args],
    () => databasesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchDatabasesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDatabasesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDatabases: refetch });
};

export const hoursGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/admin?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.hours`)}&order=name`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useHoursGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(['hour', args], () => hoursGET(Constants, args, handlers), {
    refetchInterval,
    onSuccess: () => queryClient.invalidateQueries(['hours']),
  });
};

export const FetchHoursGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useHoursGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchHours: refetch });
};

export const jigsawGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.jigsaw`)}&order=name`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useJigsawGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['jigsaws', args],
    () => jigsawGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchJigsawGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useJigsawGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchJigsaw: refetch });
};

export const mediaGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?status=${encodeQueryParam(
      `eq.active`
    )}&or=${encodeQueryParam(
      `(type.eq.media,type.eq.video,type.eq.audio,type.eq.print)`
    )}&order=name`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useMediaGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['media', args], () => mediaGET(Constants, args, handlers), {
    refetchInterval,
  });
};

export const FetchMediaGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMediaGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMedia: refetch });
};

export const mediaModalGET = (Constants, { currentMediaAPI }, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?status=${encodeQueryParam(
      `eq.active`
    )}&additional_id=${encodeQueryParam(
      `eq.${
        typeof currentMediaAPI === 'string'
          ? currentMediaAPI
          : JSON.stringify(currentMediaAPI ?? '')
      }`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useMediaModalGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['media', args],
    () => mediaModalGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchMediaModalGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  currentMediaAPI,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMediaModalGET(
    { currentMediaAPI },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMediaModal: refetch });
};

export const npsGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.treasure`)}&description=${encodeQueryParam(
      `eq.National Park Service Conserve-O-Grams`
    )}&order=name`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useNpsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['nps', args], () => npsGET(Constants, args, handlers), {
    refetchInterval,
  });
};

export const FetchNpsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useNpsGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchNps: refetch });
};

export const onlineResourcesGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.resource`)}&order=name`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useOnlineResourcesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['resources', args],
    () => onlineResourcesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchOnlineResourcesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useOnlineResourcesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchOnlineResources: refetch });
};

export const peopleActiveGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/people?status=${encodeQueryParam(
      `eq.active`
    )}&order=name`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const usePeopleActiveGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['people', args],
    () => peopleActiveGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchPeopleActiveGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePeopleActiveGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPeopleActive: refetch });
};

export const peopleModalGET = (
  Constants,
  { currentPeopleAPI },
  handlers = {}
) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/people?id=${encodeQueryParam(
      `eq.${
        typeof currentPeopleAPI === 'string'
          ? currentPeopleAPI
          : JSON.stringify(currentPeopleAPI ?? '')
      }`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const usePeopleModalGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['modal', args],
    () => peopleModalGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['modals']),
    }
  );
};

export const FetchPeopleModalGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  currentPeopleAPI,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePeopleModalGET(
    { currentPeopleAPI },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPeopleModal: refetch });
};

export const peopleSearchdynamicGET = (
  Constants,
  { peoplesearch },
  handlers = {}
) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/people?status=${encodeQueryParam(
      `eq.active`
    )}&name=${encodeQueryParam(
      `ilike.*${
        typeof peoplesearch === 'string'
          ? peoplesearch
          : JSON.stringify(peoplesearch ?? '')
      }*`
    )}&order=name`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const usePeopleSearchdynamicGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['people', args],
    () => peopleSearchdynamicGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchPeopleSearchdynamicGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  peoplesearch,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePeopleSearchdynamicGET(
    { peoplesearch },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchPeopleSearchdynamic: refetch,
  });
};

export const placesActiveGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/places?status=${encodeQueryParam(
      `eq.active`
    )}&order=name`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const usePlacesActiveGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['places', args],
    () => placesActiveGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchPlacesActiveGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePlacesActiveGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPlacesActive: refetch });
};

export const placesActiveTentativeGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/places?or=${encodeQueryParam(
      `(status.eq.active,status.eq.tentative)`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const usePlacesActiveTentativeGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['places', args],
    () => placesActiveTentativeGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchPlacesActiveTentativeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePlacesActiveTentativeGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchPlacesActiveTentative: refetch,
  });
};

export const placesInformationGET = (Constants, { currentID }, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/places?status=${encodeQueryParam(
      `eq.active`
    )}&id=${encodeQueryParam(
      `eq.${
        typeof currentID === 'string'
          ? currentID
          : JSON.stringify(currentID ?? '')
      }`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const usePlacesInformationGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['information', args],
    () => placesInformationGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['information']),
    }
  );
};

export const FetchPlacesInformationGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  currentID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePlacesInformationGET(
    { currentID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPlacesInformation: refetch });
};

export const placesModalGET = (Constants, { currentPlaceAPI }, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/places?id=${encodeQueryParam(
      `eq.${
        typeof currentPlaceAPI === 'string'
          ? currentPlaceAPI
          : JSON.stringify(currentPlaceAPI ?? '')
      }`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const usePlacesModalGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['modal', args],
    () => placesModalGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['modals']),
    }
  );
};

export const FetchPlacesModalGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  currentPlaceAPI,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePlacesModalGET(
    { currentPlaceAPI },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPlacesModal: refetch });
};

export const placesSearchdynamicGET = (
  Constants,
  { placesearch },
  handlers = {}
) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/places?status=${encodeQueryParam(
      `eq.active`
    )}&name=${encodeQueryParam(
      `ilike.*${
        typeof placesearch === 'string'
          ? placesearch
          : JSON.stringify(placesearch ?? '')
      }*`
    )}&order=name`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const usePlacesSearchdynamicGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['places', args],
    () => placesSearchdynamicGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchPlacesSearchdynamicGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  placesearch,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePlacesSearchdynamicGET(
    { placesearch },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchPlacesSearchdynamic: refetch,
  });
};

export const policiesGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/admin?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.policy`)}&order=name`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const usePoliciesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['policies', args],
    () => policiesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchPoliciesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePoliciesGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPolicies: refetch });
};

export const requestsGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/admin?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.request`)}&order=name`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useRequestsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['requests', args],
    () => requestsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchRequestsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useRequestsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchRequests: refetch });
};

export const researchGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.research`)}&order=name`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useResearchGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['research', args],
    () => researchGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchResearchGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useResearchGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchResearch: refetch });
};

export const resourcesLandAckGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/admin?id=${encodeQueryParam(
      `eq.8c1dc178-c30d-45d6-8c38-b39f66e14f20`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useResourcesLandAckGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['acknowledgement', args],
    () => resourcesLandAckGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['acknowledgements']),
    }
  );
};

export const FetchResourcesLandAckGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useResourcesLandAckGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchResourcesLandAck: refetch });
};

export const slidingGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.sliding`)}&order=name`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useSlidingGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['slidings', args],
    () => slidingGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchSlidingGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSlidingGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSliding: refetch });
};

export const treasuresGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.treasure`)}&order=${encodeQueryParam(
      `description,name`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useTreasuresGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['treasures', args],
    () => treasuresGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchTreasuresGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useTreasuresGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchTreasures: refetch });
};

export const welcomeGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/admin?id=${encodeQueryParam(
      `eq.61756ba6-9c0e-4fe2-9425-18116c325923`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useWelcomeGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['welcome', args],
    () => welcomeGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['welcomes']),
    }
  );
};

export const FetchWelcomeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useWelcomeGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchWelcome: refetch });
};

export const welcometranslationsGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/admin?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.welcome`)}&order=name`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useWelcometranslationsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['welcometranslations', args],
    () => welcometranslationsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchWelcometranslationsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useWelcometranslationsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchWelcometranslations: refetch,
  });
};

export const wordsearchGET = (Constants, _args, handlers = {}) =>
  fetch(
    `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources?status=${encodeQueryParam(
      `eq.active`
    )}&type=${encodeQueryParam(`eq.wordsearch`)}&order=additional_id`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
        'Content-Type': 'application/json',
        apikey:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useWordsearchGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['wordsearches', args],
    () => wordsearchGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchWordsearchGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useWordsearchGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchWordsearch: refetch });
};
