// This import is required if you are defining react components in this module.
import React, { Component } from 'react';

// Add any other imports you need here. Make sure to add those imports (besides "react"
// and "react-native") to the Packages section.
import { Text, Button, Image } from 'react-native';

// Define and export your components as named exports here.
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
//const myIcon = <Icon name="arrow-u-left-top" size={30} color="#900" />;

// You can use components exported from this file within a Custom Code component as
// <CustomCode.MyExampleComponent />
export function ChangeStackNavigation(props) {
  const { navigation } = props;

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Icon.Button
          name="home-circle-outline"
          onPress={() => navigation.navigate('IndexScreen')}
          color="#FFF"
          backgroundColor="#594A43"
          size={30}
        />
      ),
      headerLeft: () => (
        <Icon.Button
          name="arrow-left-circle-outline"
          onPress={() => navigation.goBack()}
          color="#FFF"
          backgroundColor="#594A43"
          size={30}
        />
      ),
    });
  }, [navigation]);

  return null;
}
